<template>
  <div class="viewcontent">
    <Title
      background="primary_bg_color"
      type="subtitle"
      :text="trendItem.fullName"
    />
    <div v-if="trendItem.subtype == 'streaming'">
      <iframe
        v-if="trendItem.list"
        :src="trendItem.list.url"
        frameborder="0"
        class="formatsize"
        :key="trendItem.code"
      ></iframe>
    </div>
    <div class="pdf" v-else-if="trendItem.subtype == 'pdf'">
      <iframe
        :src="files + checkPDF + '#zoom=FitH'"
        type="application/pdf"
        runat="server"
        name="I5"
        scrolling="yes"
        class="formatsize"
      >
      </iframe>
    </div>
    <div class="pdf" v-else-if="trendItem.subtype == 'hall'">
      <div
        class="img"
        :style="{
          'background-image': `url(${require('@/assets/baner/seminar-hall.jpeg')})`,
        }"
      ></div>
    </div>
    <div class="video" v-else>
      <Video :url="generateURL" class="formatsize" :key="trendItem.code" />
    </div>
    <!-- <div class="left">
      <div class="imgcont" v-if="trendItem.picture">
        <img :src="files + trendItem.picture" :alt="trendItem.fullName" />
      </div>
    </div> -->
    <div class="right">
      <div class="text" :class="checkFile" v-if="trendItem.description">
        <p
          v-for="(item, index) in trendItem.description.split(/<br\s*\/?>|\n/g)"
          :key="index"
          class="description"
        >
          {{ item }}
        </p>
      </div>
      <div @click="linkTo('exhibitor')" class="hover text">
        {{
          replaceTranslation({ type: 'offered', text: trendItem.exhibitorName })
        }}
      </div>
    </div>
    <div v-if="trendItem.subtype == 'hall'">
      <button
        type="button"
        @click="linkTo('url')"
        :class="'btn contrast_color ' + defineButtonClass"
        :disabled="defineButtonDisabled"
        v-if="checkStartTime == 1"
      >
        {{ translations.btn_cancel }}
      </button>
      <span v-else-if="checkStartTime == 0" class="primary_color">
        {{
          replaceTranslation({
            type: 'hall_nostart',
            text: trendItem.list.start_time,
          })
        }}
      </span>
      <span v-else-if="checkStartTime == 2" class="primary_color">
        {{
          replaceTranslation({
            type: 'hall_end',
            text: trendItem.list.start_time,
          })
        }}
      </span>
    </div>
    <ExhibitorTools
      :size="50"
      :info="{
        code: trendItem.code,
        type: 'trends',
        cards: trendItem.cards,
      }"
      v-if="trendItem.estatus >= 2"
    />
  </div>
</template>

<script>
  import ExhibitorTools from '@/components/ExhibitorTools.vue'
  import Title from '@/components/Title.vue'
  import Video from '@/components/Video.vue'

  import { mapActions, mapState } from 'vuex'
  import router from '../router'

  export default {
    name: 'Trend',
    components: {
      ExhibitorTools,
      Title,
      Video,
    },
    computed: {
      ...mapState(['trendItem', 'files', 'translations']),
      checkFile() {
        if (this.trendItem.format) {
          return 'smallp'
        }
      },
      checkStartTime() {
        if (this.trendItem.subtype == 'hall') {
          let clas = 0

          if (this.trendItem.list.start_time) {
            var d1 = new Date()
            var d2 = new Date(this.trendItem.list.start_time)
            var d3 = d2.setDate(d2.getDate() + 1)

            if (d1 >= d2 && d3 > d1) {
              clas = 1
            } else if (d3 <= d1) {
              clas = 2
            }
          }
          console.log(clas)
          return clas
        }
      },
      defineButtonClass() {
        if (this.trendItem.subtype == 'hall') {
          let clas = 'secundary_bg_color'

          if (this.trendItem.list.start_time) {
            var d1 = new Date()
            var d2 = new Date(this.trendItem.list.start_time)

            if (d1 >= d2) {
              clas = 'primary_bg_color'
            }
          }
          return clas
        }
      },
      defineButtonDisabled() {
        if (this.trendItem.subtype == 'hall') {
          let clas = true

          if (this.trendItem.list.start_time) {
            var d1 = new Date()
            var d2 = new Date(this.trendItem.list.start_time)

            if (d1 >= d2) {
              clas = false
            }
          }
          return clas
        }
      },

      checkUrl() {
        let url

        try {
          url = new URL(this.generateURL)
        } catch (_) {
          return false
        }

        return url.protocol === 'http:' || url.protocol === 'https:'
      },

      generateURL() {
        let result =
          this.trendItem.list && this.trendItem.list.url
            ? this.trendItem.list.url
            : 'error'
        return 'https://iframe.dacast.com/vod/' + result
      },

      checkPDF() {
        return this.trendItem.list.url
          ? this.trendItem.list.url
          : 'test-presentation.pdf'
      },
    },
    methods: {
      ...mapActions(['getTrendItem', 'resetTrend']),
      linkTo(value) {
        switch (value) {
          case 'url':
            window.location.href = this.trendItem.list.url
            break
          case 'exhibitor':
            router.push('/exhibitor/' + this.trendItem.exhibitorCode)
            break
        }
      },
      replaceTranslation(value) {
        let result = ''
        switch (value.type) {
          case 'offered':
            result = this.translations.label_presentation_offered.replace(
              '{var}',
              value.text
            )
            break
          case 'hall_nostart':
            result = this.translations.label_hall_nostart.replace(
              '{var}',
              value.text
            )
            break
          case 'hall_end':
            result = this.translations.label_hall_end.replace(
              '{var}',
              value.text
            )
            break
        }

        return result
      },
    },
    created() {
      this.getTrendItem(this.$route.params.id)
    },
    watch: {
      $route(to, from) {
        if (to.name == 'Trend') {
          this.getTrendItem(this.$route.params.id)
        }
      },
    },
    beforeUnmount() {
      this.resetTrend()
    },
  }
</script>

<style scoped>
  .left,
  .right {
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;
  }
  /* .left {
    width: 10%;
  } */
  .right {
    width: 100%;
  }

  .imgcont img {
    max-height: 100%;
    max-width: 100%;
    height: 115px;
    border-radius: 50%;
    overflow: hidden;
    font-size: 10px;
  }
  .img {
    width: 100%;
    position: relative;
    height: 200px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .text {
    padding: 0px 10px;
    text-align: left;
  }
  .formatsize {
    height: 550px;
    width: 100%;
  }

  .smallp {
    height: 100px;
  }

  @media screen and (max-width: 1100px) {
    .left {
      padding-left: 10px;
    }
  }
  @media screen and (max-width: 400px) {
    .formatsize {
      height: 400px;
    }
    h1 {
      font-size: 20px;
    }
    /* .description {
      padding: 0 10px;
    } */
  }
</style>
