<template>
  <div>
    <div class="toolscont nineth_bg_color fifth_color" v-if="checkFinishedExpo">
      <div
        v-for="tool in filterTools.slice(0, 6)"
        :key="tool.id"
        class="tool tooltip mouseHover"
        :class="defineSize()"
        @click="defineAction(tool.id)"
      >
        <div v-if="tool.id == 20" :class="briefcaseclass">
          <div class="icon">
            <i :class="tool.icon + ' fa-' + iconSize + 'x'"></i>
          </div>
          <div class="text eleventh_bg_color">
            <span v-if="!show.favorites"> {{ tool.title }} </span
            ><span v-else>
              {{ translations.warning_already_briefcase }}
            </span>
          </div>
        </div>
        <div
          v-else-if="tool.id == 21 && info.cards && info.cards.length > 0"
          :class="pickcardclass"
        >
          <div class="icon">
            <i :class="tool.icon + ' fa-' + iconSize + 'x'"></i>
          </div>
          <div class="text eleventh_bg_color">
            <span v-if="!show.cards">
              {{ tool.title }}
            </span>
            <span v-else>
              {{ translations.warning_already_briefcase }}
            </span>
          </div>
        </div>
        <div v-else-if="tool.id == 22" :class="leavecardclass">
          <div class="icon">
            <i :class="tool.icon + ' fa-' + iconSize + 'x'"></i>
          </div>
          <div class="text eleventh_bg_color">
            <span v-if="!show.leave">
              {{ tool.title }}
            </span>
            <span v-else>{{ translations.warning_already_sent }}</span>
          </div>
        </div>
        <div v-else-if="tool.id == 24">
          <div class="icon">
            <i :class="tool.icon + ' fa-' + iconSize + 'x'"></i>
          </div>
          <div class="text eleventh_bg_color">
            <span>
              {{ tool.title }}
            </span>
          </div>
        </div>
        <div v-else-if="tool.id == 27" :class="copycaseclass">
          <div class="icon">
            <i :class="tool.icon + ' fa-' + iconSize + 'x'"></i>
          </div>
          <div class="text eleventh_bg_color ">
            <span>
              {{ !copyed ? tool.title : translations.warning_copy_true }}
            </span>
          </div>
        </div>
        <div v-else-if="tool.id == 28">
          <div class="icon">
            <i :class="tool.icon + ' fa-' + iconSize + 'x'"></i>
          </div>
          <div class="text eleventh_bg_color ">
            <span>
              {{ tool.title }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="logged && info.cards.length > 0">
      <form
        @submit.prevent="processContact()"
        class="contactform toolform retail_bg_color"
        v-if="contactStatus == '-'"
      >
        {{ translations.form_title_contact }}
        <fieldset>
          <label for="subject">
            <span>{{ translations.label_subject }}</span>
            <input
              class="border_category previewRight"
              type="text"
              name="subject"
              v-model.trim="form.subject"
              required
            />
          </label>
        </fieldset>
        <fieldset>
          <label for="subject">
            <span>{{ translations.label_text }}</span>
            <textarea
              class="border_category "
              name="comments"
              rows="3"
              v-model.trim="form.text"
              required
            ></textarea>
          </label>
        </fieldset>
        <button type="submit" class="tertiary_bg_color contrast_color">
          {{ translations.btn_contact }}
        </button>
      </form>
      <div v-else>
        <div class="info_color">{{ emailStatus }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'

  export default {
    data() {
      return {
        briefcaseclass: '',
        leavecardclass: '',
        pickcardclass: '',
        copycaseclass: '',
        show: {
          favorites: false,
          leave: false,
          cards: false,
        },
        copyed: false,
        clickedTimer: null,
        alltools: [],
        form: {
          subject: '',
          text: '',
        },
      }
    },
    props: {
      iconSize: {
        type: Number,
        default: 2,
      },
      size: {
        type: Number,
        default: 100,
      },
      info: {
        type: Object,
        default: { code: '', type: '', card: '', cards: '', brand: '' },
      },
    },
    computed: {
      ...mapState([
        'logged',
        'tools',
        'myfavoritesBack',
        'translations',
        'date_end',
        'contactStatus',
      ]),
      emailStatus() {
        return this.contactStatus
          ? this.translations.warning_email_succes
          : this.translations.warning_email_error
      },

      filterTools() {
        // console.log(this.alltools)
        return this.alltools.filter((item) => {
          let find = null
          this.tools.forEach((element) => {
            if (item.id == element) {
              if (
                ((item.id == 21 || item.id == 24 || item.id == 28) &&
                  this.info.cards &&
                  this.info.cards.length == 0) ||
                (!this.info.cards &&
                  (item.id == 21 || item.id == 24 || item.id == 28))
              ) {
                return
              }
              let user = localStorage.getItem('loged')
              user = user ? JSON.parse(user) : { items: [] }
              if (element == 21 || element == 24 || element == 28) {
                // console.log(user.code)
                // console.log(this.info.cards)
                if (
                  this.info.cards &&
                  this.info.cards.length == 1 &&
                  user.code == this.info.cards[0]
                ) {
                  return
                }
              } else {
                // console.log(this.info)
              }
              find = item
              return item
            }
          })
          if (find) {
            return find
          }
        })
      },
      checkFinishedExpo() {
        var d1 = new Date()
        var d2 = new Date(this.date_end)
        return d1 < d2
      },
    },
    methods: {
      ...mapActions([
        'changeToolSelected',
        'setMessage',
        'leaveCardTo',
        'setExhibitorCards',
        'addContact',
        'getCardsInfo',
        'setClickCard',
        'checkMyFavorites',
        'sendContact',
        'resetChangeContact',
      ]),
      processContact() {
        this.form.card = this.info.cards[0]
        this.sendContact(this.form)
      },
      defineAction(id) {
        switch (id) {
          case 20:
          case 21:
          case 24:
          case 28:
            this.addToMyBriefcase(id)
            break
          case 22:
            this.leaveCard(id)
            break
          case 27:
            this.copyToClipboard(id)
            break
        }
      },
      async addToMyBriefcase(id) {
        if (this.logged) {
          if (id == 20) {
            var ls = localStorage.getItem('myFavorites')
            ls = ls ? JSON.parse(ls) : { items: [] }
            let find = false
            let i = 0
            let index = null
            if (ls.items.length > 0) {
              ls.items.forEach((element) => {
                if (
                  element.code == this.info.code &&
                  element.type == this.info.type
                ) {
                  find = true
                  index = i
                }
                i++
              })
            }
            if (!find) {
              ls.items.push({ code: this.info.code, type: this.info.type })
              this.show.favorites = true
            } else {
              ls.items.splice(index, 1)
              this.show.favorites = false
            }
            localStorage.setItem('myFavorites', JSON.stringify(ls))
            await this.checkMyFavorites()
          } else if (id == 21) {
            // a
            let user = localStorage.getItem('loged')
            user = user ? JSON.parse(user) : { items: [] }
            // a
            this.info.cards = this.info.cards.filter((el) => el != user.code)
            if (this.info.cards.length > 0) {
              await this.getCardsInfo(this.info.cards)
              this.changeToolSelected(id)
            }
          } else if (id == 24) {
            this.setExhibitorCards(this.info.cards)
            this.changeToolSelected(id)
          } else if (id == 28) {
            let user = localStorage.getItem('loged')
            user = user ? JSON.parse(user) : { items: [] }
            // a
            this.info.cards = this.info.cards.filter((el) => el != user.code)
            if (this.info.cards.length > 1) {
              console.log(this.info)
              await this.getCardsInfo(this.info.cards)
              this.changeToolSelected(id)
            } else if (this.info.cards.length == 1) {
              this.changeToolSelected(12)
              this.addContact(this.info.cards[0])
              await this.setClickCard(this.info.cards[0])
            }
          }
          this.defineStyle(id)
        } else {
          this.setMessage({ msg: 'msg_need_to_be_logged', to: 'login' })
          this.changeToolSelected(0)
        }
      },
      leaveCard() {
        if (this.logged) {
          this.leaveCardTo(this.info)
          this.show.leave = true
          this.defineStyle(22)
        } else {
          this.setMessage({ msg: 'msg_need_to_be_logged', to: 'login' })
          this.changeToolSelected(0)
        }
      },
      defineSize() {
        let clas = ''
        if (this.size == 50) {
          clas = 'column'
        }
        return clas
      },
      async chatTo() {
        this.changeToolSelected(12)
        this.addContact(this.data.code)
        await this.setClickCard(this.data.code)
      },
      defineStyle(tool) {
        this.show.favorites = false
        let clas = ''

        if (tool == 20) {
          var ls = localStorage.getItem('myFavorites')
          ls = ls ? JSON.parse(ls) : { items: [] }
          let find = false

          ls.items.forEach((element) => {
            if (
              element.code == this.info.code &&
              element.type == this.info.type
            ) {
              find = true
            }
          })
          this.show.favorites = find

          if (this.show.favorites) {
            clas = 'primary_color'
          } else {
            clas = 'fifth_color'
          }

          this.briefcaseclass = 'toolcont ' + clas
        } else if (tool == 21) {
          var ls = localStorage.getItem('myCards')
          ls = ls ? JSON.parse(ls) : { items: [] }
          if (ls.items) {
            ls.items.forEach((element) => {
              if (this.info.cards && element.code == this.info.cards[0]) {
                this.show.cards = true
              }
            })
          }
          if (this.show.cards) {
            clas = 'primary_color'
          }
          this.pickcardclass = 'toolcont ' + clas
        } else if (tool == 22) {
          var ls = localStorage.getItem('sendCards')
          ls = ls ? JSON.parse(ls) : { items: [] }
          ls.items.forEach((element) => {
            if (element.code == this.info.cards[0]) {
              this.show.leave = true
            }
          })
          if (this.show.leave) {
            clas = 'primary_color'
          }
          this.leavecardclass = 'toolcont ' + clas
        } else if (tool == 27) {
          if (this.copyed) {
            this.copycaseclass = 'primary_color clictext'
            this.clickedTimer = setInterval(() => {
              this.copycaseclass = ''
              this.copyed = false
            }, 6000)
          }
        }
        // return clas + ' toolcont'
      },
      copyToClipboard(tool) {
        if (navigator.clipboard && window.isSecureContext) {
          const clipboardData =
            event.clipboardData ||
            window.clipboardData ||
            event.originalEvent?.clipboardData ||
            navigator.clipboard

          clipboardData.writeText(window.location.href)
          this.copyed = true
          this.defineStyle(tool)
        }
      },
      addTranslations() {
        if (this.translations) {
          this.alltools = [
            {
              id: 20,
              title: this.translations.label_save_briefcase,
              icon: 'fas fa-bookmark',
            },
            {
              id: 21,
              title: this.translations.label_pick_card,
              icon: 'far fa-address-card',
            },
            {
              id: 22,
              title: this.translations.label_leave_card,
              icon: 'fas fa-address-card',
            },
            // {
            //   id: 23,
            //   title: 'Start instant chat',
            //   icon: 'far fa-comments',
            // },
            {
              id: 24,
              title: this.translations.label_video_meeting,
              icon: 'fas fa-video',
            },
            {
              id: 27,
              title: this.translations.label_copy,
              icon: 'far fa-copy',
            },
            {
              id: 28,
              title: this.translations.label_chat_exhibitor,
              icon: 'far fa-comment-dots',
            },
          ]
        }
      },
    },

    created() {
      this.addTranslations()
      this.defineStyle(20)
      this.defineStyle(21)
      this.defineStyle(22)
    },
    beforeUnmount() {
      clearInterval(this.clickedTimer)
      this.resetChangeContact()
    },
    watch: {
      $route(newValue, oldValue) {
        this.defineStyle(20)
        this.defineStyle(21)
        this.defineStyle(22)
      },
      'myfavoritesBack.items'(newValue, oldValue) {
        this.defineStyle(20)
      },
    },
  }
</script>

<style scoped>
  fieldset {
    border-width: 0;
  }
  .toolcont {
    padding: 0 5px;
  }
  .toolscont {
    text-align: center;
  }
  .tooltip {
    position: relative;
  }
  .tooltip .text {
    visibility: hidden;
    width: 120px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
  }
  .tooltip:hover .text,
  .tooltip .clictext .text {
    visibility: visible;
  }
  .tool {
    display: inline-block;
    /* box-shadow: 3px 3px #d4d4d4; */
    margin: 10px;
    vertical-align: middle;
    padding: 10px 0;
  }

  .icon,
  .text {
    display: inline-block;
    vertical-align: middle;
  }
  /* .icon {
    width: 30%;
  } */
  /* .text {
    width: 70%;
    height: 60px;
    line-height: 60px;
    text-align: center;
  } */
  span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    padding: 5px;
  }
  .column {
    font-size: 13px;
    width: 37px;
    margin: 5px;
  }
  .contactform {
    padding: 15px 0;
  }
  .contactform label {
    width: 100%;
  }
  .contactform span {
    display: block;
  }
  .contactform input,
  .contactform textarea {
    border-width: 3px;
    border-style: none none solid none;
    box-sizing: border-box;
    padding: 0 20px;
    width: 100%;
    height: auto;
    margin: 0;
    background-color: transparent;
  }

  .contactform textarea {
    resize: vertical;
  }

  @media screen and (max-width: 400px) {
    .text {
      font-size: 10px;
    }
  }
</style>
